.portfolio-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.portfolio {
  display: flex;
  flex-direction: column;
  padding: 5%;
  max-height: 100%;
  justify-content: space-between;
}

.intro-portfolio-container {
  font-size: 20pt;
}

.code-portfolio-text-container {
  font-size: 40pt;
}

#my-portfolio {
  font-size: 50pt;
}

@media only screen and (max-width: 1024px) {
  #my-portfolio {
    font-size: 22pt;
  }
  .portfolio-title {
    padding: 8pt;
  }
}
