.created-container {
  /* display: flex;
  margin-top: 40px;
  justify-content: center;
  text-align: center; */
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 30pt;
}

.created-container a {
  text-decoration: none;
  font-size: 12pt;
}

.created-container p {
  font-size: 12pt;
}
