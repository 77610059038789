.projects-container {
  margin-top: 20pt;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: 30pt;
}

.project-container {
  display: flex;
  flex-direction: column;
  gap: 12pt;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 2%;
}

.intro-portfolio-container {
  margin-top: 10pt;
}

.images-container {
  display: flex;
  flex-direction: row;
  gap: 5pt;
  align-items: center;
}

.wider-image-container {
  gap: 5pt;
}

#project-button {
  width: 286pt;
  max-width: 90vw;
  height: 74pt;
  color: var(--mainColor);
  border-radius: 33% 67% 52% 48% / 35% 47% 53% 65%;
  border-color: var(--mainColorLight);
}

#project-button:hover {
  color: var(--mainColorLight);
  cursor: pointer;
}

.project-image {
  width: 100%;
  max-width: 200pt;
  min-height: 50%;
  max-height: 50%;
}

.wider-image {
  height: 100%;
  max-height: 400pt;
  min-width: 50%;
  max-width: 50%;
}

.project-title {
  font-size: 35pt;
  color: var(--mainColor);
}

@media only screen and (max-width: 1024px) {
  #project-button {
    width: 230pt;
    height: 50pt;
  }
  .wider-image-container {
    flex-direction: column;
  }

  /* .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 5pt;
    align-items: center;
  } */

  .project-title {
    font-size: 20pt;
    color: var(--mainColor);
    padding: 8pt;
  }

  .intro-portfolio-container {
    font-size: 13pt;
    padding: 8pt;
  }
  .project-image {
    max-width: 45%;
  }

  .wider-image {
    min-width: 100%;
    max-width: 100pt;
  }
  .projects-container {
    margin-top: 10pt;
  }
}

@media only screen and (max-width: 1314px) {
  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 5pt;
    align-items: center;
  }
}
