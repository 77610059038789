@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
  /* --mainColor: #a408c6; */
  /* --mainColorLight: #afbbf2; */
  --textColor: #d3fff3;

  --secondaryColor: #181a1b;
  --mainColor: #8a61b3;
  /* ^^ This purple to match the code */
  --mainColorLight: #f1cc02;

  /* ^^ This yellow to match the code*/
  /* --textColor: #87ab6d; */
  /* ^^This green to match the code */
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 17pt;
  color: var(--textColor);
  background-color: var(--secondaryColor);
}

img {
  width: 100%;
  max-width: 90vw;
  border-radius: 15pt;
  border: 5pt;
  border: solid;
  border-color: var(--mainColor);
}

@media only screen and (max-width: 1024px) {
  * {
    font-family: 'Poppins', sans-serif;
    font-size: 12pt;
    color: var(--textColor);
    background-color: var(--secondaryColor);
  }
}
