/*Code About CSS:*/

.name {
  color: #ba5d66;
  font-size: 20pt;
}

.variable {
  color: var(--mainColor);
}

.string {
  color: var(--textColor);
  font-size: 20pt;
}

.parenthesis {
  color: var(--mainColorLight);
}

#my-name {
  font-size: 50pt;
}

.code-text-container {
  display: block;
}

#my-pic {
  border-radius: 33% 67% 52% 48% / 35% 47% 53% 65%;
  max-width: 100%;
  width: 350pt;
}

#speaker-pic {
  margin-top: 8pt;
  max-width: 40pt;
  border: none;
  align-items: center;
}

#speaker-pic:hover {
  cursor: pointer;
}

.img-intro-about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.my-picture-container {
  max-width: 400pt;
  margin: 20pt;
  min-width: 50pt;
}

.intro-about-container {
  display: flex;
  flex-direction: column;
  gap: 50pt;
  margin: 20pt;
}

.how-to-speaker {
  display: flex;
  align-items: center;
}

.intro-text-container {
  margin: 40pt;
}

.subtitle {
  font-size: 20pt;
}

.contact-function-container {
  display: flex;
  flex-direction: row;
  margin-left: 40pt;
}

.function-container {
  max-width: 400pt;
  min-width: 400pt;
  margin: 10pt;
  align-items: center;
}

.code-text-container-my-name-title {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .img-intro-about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .code-text-container-my-name-title {
    display: block;
    padding: 14pt;
  }

  #my-name-title {
    font-size: 22pt;
  }

  .contact-function-container {
    display: flex;
    flex-direction: column;
    margin: 10pt;
  }

  .function-container {
    /* max-width: 400pt;
    min-width: 400pt; */
    margin: 0pt;
    max-width: 300pt;
    min-width: 90%;
    padding: 8pt;
  }

  .subtitle {
    font-size: 13pt;
  }

  .my-picture-container {
    max-width: 200pt;
    min-width: 50pt;
    margin-bottom: 0pt;
    margin-top: 0pt;
  }

  .name {
    font-size: 13pt;
  }

  .variable {
    font-size: 13pt;
  }

  .string {
    font-size: 13pt;
  }

  .parenthesis {
    font-size: 13pt;
  }

  #my-name {
    font-size: 22pt;
    display: none;
  }

  .intro-text-container {
    margin: 10pt;
    padding: 8pt;
  }

  .intro-about-container {
    gap: 10pt;
    margin: 10pt;
    padding: 8pt;
    margin-top: 0;
  }

  #my-pic {
    max-width: 100%;
    width: 150pt;
  }
}
